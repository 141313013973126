import { Injectable } from '@angular/core';
import { MsalService } from '@azure/msal-angular';
import { LoggedOnUser } from '../../models/loggedonUser.model';

@Injectable({
  providedIn: 'root',
})
export class UniperAuthService {
  constructor(private msal: MsalService) {}

  public get IsLoggedIn() {
    return this.msal.instance.getAllAccounts().length > 0;
  }

  public get LoggedOnUser(): LoggedOnUser | null {
    if (this.IsLoggedIn) {
      const account = this.msal.instance.getAllAccounts()[0];
      return new LoggedOnUser(account.name ?? 'not specfied', account.username);
    } else return null;
  }
}
