export class LoggedOnUser{
    constructor(n: string, e: string) {
        this.name = n;
        this.email = e;
        this.kid = e.split("@")[0];
    }
    name: string;
    email: string;
    kid: string;

    public get displayText() {
        return `${this.name} (${this.kid})`;
    } 
}